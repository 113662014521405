/* custom properties */
* {
  /* z-indexに指定する変数を設定。ヘッダーを100にしてます。 */
  --header-zindex: 100;
  /* サイトのテーマカラーを設定 */
  --theme-color: #4199e1;
  --theme-color-hover: #1e78c2;
  --theme-background-color: #ebf8ff;
  --gray-color: #a0aec0;
}

/* サイト全体に共通して適用しているスタイル */
body {
  font-family: "Hiragino Kaku Gothic Pro", "メイリオ",
               -apple-system, BlinkMacSystemFont,
               'Segoe UI', 'Roboto', 'Oxygen','Ubuntu','Cantarell',
               'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}
:before, :after {
  box-sizing: border-box;
}
section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 3rem;
}
img {
  width: 100%;
  max-width: 800px;
  height: auto;
}
h1, h2, h3, h4, p {
  margin: 0;
  padding: 0;
  /* 自動カーニングで字詰めをして読みやすくしています */
  font-feature-settings: 'palt';
}
h2 {
  font-size: 3rem;
  font-weight: 600;
  padding-bottom: 2rem;
}
h3 {
  font-size: 1.75rem;
  font-weight: 600;
}
h4 {
  font-size: 1.5rem;
  font-weight: 400;
}
/* 見にくい改行を防ぐために、決めた部分で改行を入れています */
/* -pcはPC表示で、-spはスマホ表示でそれぞれ改行を有効にするクラスです */
.br-disp-pc {
  display: block;
}
.br-disp-sp {
  display: none;
}
/* スマホ画面サイズのスタイル */
@media ( max-width: 640px ) {
  section {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  h2 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  .br-disp-pc {
    display: none;
  }
  .br-disp-sp {
    display: block;
  }
}